import TVKEYS from "../../tv/tvkeys";
import AbstractView from "../AbstractView";

class Sudoku extends AbstractView {
    constructor(params) {
        super(params);
        this.setTitle('Sudoku');
        this.setActiveHeader('header-games');
        this.playing = true;
        this.handled = false;
    }

    async getHtml() {
        return ` 
        <div class="sudoku-wrapper">
            <div class='button' id='backButton' href="/games" data-link="" tabindex="-1">
                <span class='button-text' id="back_text"> &lt;Geri </span>
            </div>            
            <h2 class="title-text" id="title_text">Sudoku</h2>
            <div class="hint-text" id="hint_text"></div>
            <div class="sudoku-container">
                ${Array.from({ length: 81 }, (_, i) => `<div class='sudoku-cell' id='cell${i}'></div>`).join('')}
            </div>
            <div id='notification' class='notification'></div>
            <div class='button-container'>
                <div class='button' id='checkButton'>
                    <div class='button-blue'></div>
                    <span class='button-text' id="control_text">Doğru Çözümü Göster</span>
                </div>
                <div class='button' id='startNewButton'>
                    <div class='button-green'></div>
                    <span class='button-text'>Yeni Oyun Başlat</span>
                </div>
                <div class='button'>
                    <div class='button-red' id='undoButton'></div>
                    <span class='button-text'>Hücreyi Temizle</span>
                </div>
                <div class='button'>
                    <div class='button-yellow' id='hintButton'></div>
                    <span class='button-text'>İpucu Göster</span>
                </div>
            </div>
        </div>
        `;
    }

    async navigate() {
    }

    async afterRender() {
        var currentSelection = 0;
        var board = [];
        for (var i = 0; i < 81; i++) {
            board[i] = '';
        }
        var initialBoard = [];
        var initialBoardEmpty =[];
        var remainingHints = 3;

        const game = () => {
            function moveSelection(change) {
                unhighlightSquare(currentSelection);
                currentSelection = (currentSelection + change + 81) % 81;
                highlightSquare(currentSelection);
            }

            function highlightSquare(index) {
                var cell = document.getElementById('cell' + index);
                if (cell) {
                    cell.setAttribute('data-original-color', cell.style.backgroundColor);
                    cell.style.backgroundColor = '#ADD8E6';
                }
            }

            function unhighlightSquare(index) {
                var cell = document.getElementById('cell' + index);
                if (cell) {
                    var originalColor = cell.getAttribute('data-original-color') || '#798bff';
                    cell.style.backgroundColor = originalColor;
                }
            }

            function updateHint() {
                var hint = document.getElementById("hint_text");
                hint.textContent = "Kalan ipucu hakkınız: " + remainingHints;
            }

            function updateControlText() {
                var allFilled = true;
                for (var i = 0; i < board.length; i++) {
                    if (board[i] === '') {
                        allFilled = false;
                        break;
                    }
                }

                if (board.length == 81 && allFilled) {
                    var controlTextElement = document.getElementById("control_text");
                    controlTextElement.textContent = "Cevabını kontrol et";
                }
            }

            function showHint() {
                if (remainingHints > 0) {

                    if (board[currentSelection] === '') {
                        board[currentSelection] = initialBoard[currentSelection]; // Reveal the correct value from the initial board
                        document.getElementById('cell' + currentSelection).textContent = initialBoard[currentSelection];
                        document.getElementById('cell' + currentSelection).classList.add('hint-revealed');
                        remainingHints--;
                        updateHint();
                    }

                } else {
                    document.getElementById("hint_text").textContent = "Ipucu hakkıniz bitti.";
                }
            }

            function generateSudokuBoard() {
                currentSelection = 0;
                for (var i = 0; i < 81; i++) {
                    initialBoard[i] = '';
                }

                for (var j = 0; j < 81; j++) {
                    var cell = document.getElementById('cell' + j);
                    cell.classList.remove('initial-clue');
                    cell.classList.remove('hint-revealed');
                }

                function canPlaceNumber(row, col, num) {
                    for (var i = 0; i < 9; i++) {
                        if (initialBoard[row * 9 + i] === num || initialBoard[i * 9 + col] === num) {
                            return false;
                        }
                    }

                    var boxRowStart = Math.floor(row / 3) * 3;
                    var boxColStart = Math.floor(col / 3) * 3;
                    for (var k = 0; k < 3; k++) {
                        for (var j = 0; j < 3; j++) {
                            if (initialBoard[(boxRowStart + k) * 9 + boxColStart + j] === num) {
                                return false;
                            }
                        }
                    }

                    return true;
                }

                function solveSudoku() {
                    for (var row = 0; row < 9; row++) {
                        for (var col = 0; col < 9; col++) {
                            if (initialBoard[row * 9 + col] === '') {
                                var shuffledNumbers = shuffleNumbers(1, 9);

                                for (var k = 0; k < shuffledNumbers.length; k++) {
                                    var num = shuffledNumbers[k];
                                    if (canPlaceNumber(row, col, num)) {
                                        initialBoard[row * 9 + col] = num;
                                        if (solveSudoku()) {
                                            return true;
                                        } else {
                                            initialBoard[row * 9 + col] = '';
                                        }
                                    }
                                }
                                return false;
                            }
                        }
                    }
                    return true;
                }


                function shuffleNumbers(min, max) {
                    var numbers = [];
                    for (var i = min; i <= max; i++) {
                        numbers.push(i);
                    }
                    for (var k = numbers.length - 1; k > 0; k--) {
                        var j = Math.floor(Math.random() * (k + 1));
                        var temp = numbers[k];
                        numbers[k] = numbers[j];
                        numbers[j] = temp;
                    }
                    return numbers;
                }

                solveSudoku();

                initialBoardEmpty = initialBoard.slice();


                var minEmptyCells = 35;
                var maxEmptyCells = 40;
                var emptyCells = Math.floor(Math.random() * (maxEmptyCells - minEmptyCells + 1)) + minEmptyCells;

                while (emptyCells > 0) {
                    var randomIndex = Math.floor(Math.random() * 81);
                    if (initialBoardEmpty[randomIndex] !== '') {
                        initialBoardEmpty[randomIndex] = '';
                        emptyCells--;
                    }
                }

                for (var l = 0; l < 81; l++) {
                    if (initialBoardEmpty[l] !== '') {
                        document.getElementById('cell' + l).classList.add('initial-clue');
                    }
                }
                board = initialBoardEmpty.slice();
                updateBoard();
            }

            function makeMove(value) {
                if (board[currentSelection] === '') {
                    board[currentSelection] = value.toString();
                    updateBoard();
                    updateControlText();
                }
            }


            function deleteMove() {
                if (initialBoardEmpty[currentSelection] === ''){
                    board[currentSelection] = '';
                }

                updateBoard();
            }

            function checkSolution() {
                var userBoard = board.slice();
                var isBoardFull = true;
                for (var i = 0; i < userBoard.length; i++) {
                    if (userBoard[i] === '') {
                        isBoardFull = false;
                        break;
                    }
                }
                function isValid(board) {
                    function isValidRow(row) {
                        var seen = {};
                        for (var col = 0; col < 9; col++) {
                            var num = board[row * 9 + col];
                            if (num !== '' && seen[num]) {
                                return false;
                            }
                            seen[num] = true;
                        }
                        return true;
                    }

                    function isValidCol(col) {
                        var seen = {};
                        for (var row = 0; row < 9; row++) {
                            var num = board[row * 9 + col];
                            if (num !== '' && seen[num]) {
                                return false;
                            }
                            seen[num] = true;
                        }
                        return true;
                    }

                    function isValidBox(boxStartRow, boxStartCol) {
                        var seen = {};
                        for (var i = 0; i < 3; i++) {
                            for (var j = 0; j < 3; j++) {
                                var num = board[(boxStartRow + i) * 9 + boxStartCol + j];
                                if (num !== '' && seen[num]) {
                                    return false;
                                }
                                seen[num] = true;
                            }
                        }
                        return true;
                    }

                    for (var i = 0; i < 9; i++) {
                        if (!isValidRow(i) || !isValidCol(i)) {
                            return false;
                        }
                    }

                    for (var row = 0; row < 9; row += 3) {
                        for (var col = 0; col < 9; col += 3) {
                            if (!isValidBox(row, col)) {
                                return false;
                            }
                        }
                    }

                    return true;
                }

                function highlightErrors() {
                    for (var i = 0; i < 81; i++) {
                        var cell = document.getElementById('cell' + i);
                        if (userBoard[i] != initialBoard[i]) {
                            cell.style.backgroundColor = 'red';
                            cell.style.fontWeight = '700';
                            cell.style.fontSize = '14px';
                            cell.innerText = userBoard[i] + " - " + initialBoard[i];
                        }
                    }
                }

                function highlightEmptyCells() {
                    for (var i = 0; i < 81; i++) {
                        var cell = document.getElementById('cell' + i);
                        if (userBoard[i] === '') {
                            cell.style.backgroundColor = 'yellow';
                            cell.style.fontWeight = '700';
                            cell.textContent = initialBoard[i];
                        }
                    }
                }

                var notification = document.getElementById('notification');

                if (isValid(userBoard)) {
                    if (isBoardFull) {
                        notification.textContent = 'Tebrikler! Sudokuyu çözdünüz.';
                        return;
                    } else {
                        notification.textContent = 'Sudokunuzda eksik hücreler var.';
                        return;
                    }

                } else {
                    if (isBoardFull) {
                        highlightErrors();
                        notification.textContent = 'Sudokunuzda hata var.';
                    } else {
                        highlightErrors();
                        highlightEmptyCells();
                        notification.textContent = 'Sudokunuzda hata var. Eksik hücreler bulunuyor.';
                    }
                }

                var cur = document.getElementById('cell' + currentSelection);
                if (cur.style.backgroundColor == 'yellow' || cur.style.backgroundColor == 'red') {
                    highlightSquare(currentSelection);

                }
            }

            function clearStyles() {
                for (var i = 0; i < 81; i++) {
                    var cell = document.getElementById('cell' + i);
                    if (cell) {
                        cell.style.backgroundColor = '#798bff';
                        cell.style.fontWeight = '400';
                        cell.style.fontSize = '20px';
                        cell.setAttribute('data-original-color', '#798bff');
                    }
                }
            }

            function updateBoard() {
                for (var i = 0; i < 81; i++) {
                    var cell = document.getElementById('cell' + i);
                    if (board[i] !== '') {
                        cell.innerText = board[i];
                    } else {
                        cell.innerText = '';
                    }

                }
            }


            function startNewGame() {
                clearStyles();
                generateSudokuBoard();
                var notification = document.getElementById('notification');
                notification.textContent = ' ';
                remainingHints = 3;
                updateHint();
                document.getElementById('control_text').textContent = 'Doğru cevabı göster';

            }

            generateSudokuBoard();
            highlightSquare(currentSelection);
            updateHint();
            return {
                moveSelection, checkSolution, deleteMove, showHint, makeMove, startNewGame
            };


        };

        const gameFunctions = game();
        let navigationHandler = createNavigationHandler(gameFunctions);
        document.addEventListener('keydown', navigationHandler);
        
        function createNavigationHandler(
            { moveSelection, checkSolution, deleteMove, showHint, makeMove, startNewGame }
        ) {
            return function (e) {
                switch (e.keyCode) {
                    case TVKEYS.VK_LEFT:
                        moveSelection(-1);
                        break;
                    case TVKEYS.VK_RIGHT:
                        moveSelection(1);
                        break;
                    case TVKEYS.VK_UP:
                        moveSelection(-9);
                        break;
                    case TVKEYS.VK_DOWN:
                        moveSelection(9);
                        break;
                    case TVKEYS.VK_GREEN:
                        startNewGame();
                        break;
                    case TVKEYS.VK_BLUE:
                        checkSolution();
                        break;
                    case TVKEYS.VK_RED:
                        deleteMove();
                        break;
                    case TVKEYS.VK_YELLOW:
                        showHint();
                        break;
                    case TVKEYS.VK_1:
                        makeMove(1);
                        break;
                    case TVKEYS.VK_2:
                        makeMove(2);
                        break;
                    case TVKEYS.VK_3:
                        makeMove(3);
                        break;
                    case TVKEYS.VK_4:
                        makeMove(4);
                        break;
                    case TVKEYS.VK_5:
                        makeMove(5);
                        break;
                    case TVKEYS.VK_6:
                        makeMove(6);
                        break;
                    case TVKEYS.VK_7:
                        makeMove(7);
                        break;
                    case TVKEYS.VK_8:
                        makeMove(8);
                        break;
                    case TVKEYS.VK_9:
                        makeMove(9);
                        break;
                    case TVKEYS.VK_BACK:
                        document.removeEventListener('keydown', navigationHandler);
                        document.getElementById("backButton").click();
                        break;
                    default:
                        break;
                }
            };
        }


    }

}

export default Sudoku;
