import TVKEYS from "../../tv/tvkeys";
import AbstractView from "../AbstractView";
import { deactivateApp } from "../../components/broadcast-object";
import bg0 from '../../../images/games/kelime-tahmin/bg0.png';
import bg1 from '../../../images/games/kelime-tahmin/bg1.png';
import bg2 from '../../../images/games/kelime-tahmin/bg2.png';
import bg3 from '../../../images/games/kelime-tahmin/lose.png';


class KelimeTahmin extends AbstractView {
    constructor(params) {
        super(params);
        this.setTitle('Kelime Tahmin');
        this.setActiveHeader('header-games');
        this.playing = true;
        this.handled = false;

        this.globalVariables = {
            navigationHandler: null,
            rowMini: ['row1', 'row2', 'row0', 'row3'],
            rowBig: ['row0', 'row1', 'row2', 'row3'],
            startingPointMini: [17, 17],
            startingPointBig: [13, 17, 17, 13],
            lettersLength: 29,
            answerIndex: 11,
            maxTime: 240,
            rightOfUse: 7,
            checkedLetters: [],
            data: [
                {
                    question: 'Bir meslek?',
                    answer: 'AKTÜER',
                    options: ['AKTÜER', 'ARTİST', 'AKTRİS', 'ARPİST'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'SİLAHÇI',
                    options: ['SİMİTÇİ', 'SİLAHÇI', 'SİMYACI', 'STİLİST'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'ŞAPKACI',
                    options: ['ŞAPKACI', 'ŞEKERCİ', 'ŞARAPÇI', 'ŞARKICI'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'MÜBAŞİR',
                    options: ['MÜVAŞİR', 'MÜEZZİN', 'MÜBAŞİR', 'MUHAFIZ'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'KALAYCI',
                    options: ['KALIPÇI', 'KEBAPÇI', 'KAYIKÇI', 'KALAYCI'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'ODUNCU',
                    options: ['OYUNCU', 'OTELCİ', 'OYMACI', 'ODUNCU'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'SPİKER',
                    options: ['SUNUCU', 'SUFLÖR', 'SPİKER', 'SARRAF'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'YARBAY',
                    options: ['YARBAY', 'YARGIÇ', 'VATMAN', 'ZABITA'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'MÜSTEŞAR',
                    options: ['MÜTERCİM', 'MÜSTEŞAR', 'SÜNNETÇİ', 'SÜVERYAN'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'NÖROLOG',
                    options: ['NÖROLOG', 'BİYOLOG', 'ETNOLOG', 'PARALOG'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'HEMATOLOG',
                    options: ['HEMATOLOG', 'JİNEKOLOG', 'KRİPTOLOG', 'ORNİTOLOG'],
                },
                {
                    question: 'Bir meslek?',
                    answer: 'GARSON',
                    options: ['GEZFİN', 'GARSON', 'HAVACI', 'HALICI'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'AKARSU DEBİSİ',
                    options: ['AKARSU YATAĞI', 'AKARSU KIYISI', 'AKARSU DEBİSİ', 'AKARSU REJİMİ'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'FAY',
                    options: ['DAĞ', 'DAM', 'LAV', 'FAY'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'NÜFUS',
                    options: ['NÜFUS', 'HUMUS', 'BULUT', 'HÖYÜK'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'LAPYA',
                    options: ['LAPYA', 'UVALA', 'DOLİN', 'POLYE'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'İZOBAR',
                    options: ['İZOBAT', 'İZOYET', 'İZOBAR', 'IŞIMA'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'DOĞAL REZERV',
                    options: ['DOĞAL KAYNAK', 'DOĞAL REZERV', 'DÜŞÜK GELGİT', 'DENİZ İKLİMİ'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'ENLEM',
                    options: ['EKSEN', 'ENLEM', 'METAN', 'LİKEN'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'POLDER',
                    options: ['POLDER', 'PROFİL', 'OTOYOL', 'BOYLAM'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'DELTA',
                    options: ['DÖVİZ', 'DULDA', 'DELTA', 'DOĞAL'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'BAZALT',
                    options: ['BAZALT', 'BOZKIR', 'BASINÇ', 'BENTOS'],
                },
                {
                    question: 'Bir coğrafi terim?',
                    answer: 'TÜR',
                    options: ['KÖY', 'TAN', 'SİT ', 'TÜR'],
                },
                {
                    question: 'Eski inanışlara bir tanrı?',
                    answer: 'ZEUS',
                    options: ['ZEUS', 'HERA', 'RHEA ', 'GAİA'],
                },
                {
                    question: 'Eski inanışlara bir tanrı?',
                    answer: 'ANUBİS',
                    options: ['ANUKET', 'ANUBİS', 'SERKET', 'OSİRİS'],
                },
                {
                    question: 'Eski inanışlara bir tanrı?',
                    answer: 'KUARA',
                    options: ['KUYAŞ', 'KAYRA', 'KUBAİ', 'KUARA'],
                },
                {
                    question: 'Bir sayı?',
                    answer: 'BİR',
                    options: ['BİN', 'İKİ', 'BİR', 'BEŞ'],
                },
                {
                    question: 'Bir sayı?',
                    answer: 'SEKSEN BEŞ',
                    options: ['SEKSEN BEŞ', 'DOKSAN İKİ ', 'SEKSEN BİR', 'DOKSAN BEŞ'],
                },
                {
                    question: 'Bir sayı?',
                    answer: 'BEŞ MİLYON',
                    options: ['BİR MİLYON', 'İKİ MİLYAR', 'BEŞ MİLYON', 'BEŞ MİLYAR'],
                },
                {
                    question: 'Bir ülke?',
                    answer: 'PALAU',
                    options: ['PARAU', 'PALAU', 'NAURU', 'SAMAO'],
                },
                {
                    question: 'Bir ülke?',
                    answer: 'İSPANYA',
                    options: ['İSKOÇYA', 'İSVİÇRE ', 'İSPANYA', 'İZLANDA'],
                },
                {
                    question: 'Bir ülke?',
                    answer: 'POLONYA',
                    options: ['POLONYA', 'BOLİVYA', 'ROMANYA', 'MALEZYA'],
                },
                {
                    question: 'Bir ülke?',
                    answer: 'BURUNDİ',
                    options: ['BERMUDA', 'BELARUS', 'BURUNDİ ', 'BELÇİKA'],
                },
                {
                    question: 'Bir ülke?',
                    answer: 'SLOVAKYA',
                    options: ['SLOVAKYA', 'SLOVENYA', 'TAZMANYA ', 'SRİLANKA'],
                },
                {
                    question: 'Bir ülke?',
                    answer: 'MACARİSTAN',
                    options: ['MACARİSTAN', 'YUNANİSTAN', 'TACİKİSTAN', 'ERMENİSTAN'],
                },
                {
                    question: 'Bir Türk ünlü?',
                    answer: 'GÜLŞEN',
                    options: ['GÜLDEN', 'GÜLŞEN', 'GÜLHAN', 'GÖKSEL'],
                },
                {
                    question: 'Bir Türk ünlü?',
                    answer: 'TÜLAY ÖZER',
                    options: ['TÜLAY ÖZER', 'FÜSUN ÖNAL', 'TÜĞBA ÖZAY', 'FUNDA ARAR'],
                },
                {
                    question: 'Bir Türk ünlü?',
                    answer: 'ALPAY',
                    options: ['ALTAY', 'AJDAR', 'ATİYE ', 'ALPAY'],
                },
                {
                    question: 'Bir Türk ünlü?',
                    answer: 'ENİS ARIKAN',
                    options: ['ELİF PEKTAŞ', 'ENİS ARIKAN', 'ELİF SÖNMEZ', 'EMİN GÜRSOY'],
                },
                {
                    question: 'Bir Türk ünlü?',
                    answer: 'DENİZ ÖZERMAN',
                    options: ['DENİZ ÖZERMAN', 'DENİZ AKKAYA', 'DENİZ TÜRKALİ', 'DEMİR KARAHAN'],
                },
                {
                    question: 'Bir Türk ünlü?',
                    answer: 'LEVENT ÜLGEN',
                    options: ['LEVENT SÜLÜN', 'LEVENT TÜLEK', 'LEVENT ÜLGEN', 'LEVENT ÜNSAL'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'BETON',
                    options: ['DUVAR', 'BETON', 'BASIK ', 'ESKİS'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'EŞİK',
                    options: ['EŞİK', 'ETEK', 'AÇIT ', 'HARÇ'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'LAMİNE',
                    options: ['LAMBRİ', 'LAMİNE', 'KANOPİ', 'İSKELE'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'PAFTA',
                    options: ['PARKE', 'PAFTA', 'ZEMİN ', 'TAVAN'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'GRANİT',
                    options: ['GRANİT', 'İSKELE', 'JAKUZİ', 'KARKAS'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'NİŞ',
                    options: ['HOL', 'ODA', 'NİŞ', 'ŞAP'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'ŞANTİYE',
                    options: ['TESVİYE', 'ŞANTİYE', 'VAZİYET', 'VİYADÜK'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'TAPU',
                    options: ['TAPU', 'TAKS', 'VİNÇ ', 'SÖVE'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'RAYİÇ BEDEL',
                    options: ['RAYİÇ BEDEL', 'RADYE TEMEL', 'ISLAK HACİM', 'MİKRO BETON'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'ALINLIK',
                    options: ['ADERANS', 'ALINLIK', 'FİRKETE', 'İHRAZAT'],
                },
                {
                    question: 'Bir mimari terim?',
                    answer: 'DRENAJ',
                    options: ['ANKRAJ', 'DRENAJ', 'RÖLEVE', 'PARSEL'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'AKTİN',
                    options: ['AKSON', 'AKTİF', 'AMİNO', 'AKTİN'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'DİHİBRİT',
                    options: ['DİFÜZYON', 'DİHİBRİT', 'DOMİNANT', 'DÖLLENME '],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'EREPDİM',
                    options: ['ERGOTİN', 'EMBRİYO', 'EREPDİM', 'EFEKTÖR'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'FİBRİN',
                    options: ['FİBRİN', 'FİBRİL', 'FUNDUS', 'FİLTRE'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'GENOM',
                    options: ['GENOM', 'GAMED', 'GUATR', 'GONAD'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'HEMOGLOBİN',
                    options: ['HEMOGLOBİN', 'İNTERFERON', 'KLOROPLAST', 'KONDROSİT'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'KOHLEA',
                    options: ['KORNEA', 'KORONA', 'KOHLEA', 'KOLONİ'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'MEZODERM',
                    options: ['MEZODERM', 'MUTASYON', 'NÖROGLİA', 'MİYAKARD'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'ÖKARYOT',
                    options: ['ÖKARYOT', 'OOGENEZ', 'OTOTROF', 'ORGANEL'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'PERİTON',
                    options: ['PERİOST', 'PLAZMİD', 'PERİTON', 'PATOJEN'],
                },
                {
                    question: 'Bir biyoloji terimi?',
                    answer: 'SÜKSESYON',
                    options: ['TELEMERE', 'VARYASYON', 'YOĞUNLUK', 'SÜKSESYON'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'SODYUM',
                    options: ['GALYUM', 'LİTYUM', 'SODYUM', 'HELYUM'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'KROM',
                    options: ['KLOR', 'FLOR', 'BROM', 'KROM'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'PALADYUM',
                    options: ['RUTENYUM', 'PALADYUM', 'KADMİYUM', 'SAMARYUM'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'URANYUM',
                    options: ['URANYUM', 'KÜRİYUM', 'OSMİYUM', 'İNDUYUM'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'NOBELYUM',
                    options: ['BOHRİYUM', 'HASSİYUM', 'NOBELYUM', 'FERMİYUM'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'UNUNOKTİYUM',
                    options: ['UNUNOKTİYUM', 'LİVERMORYUM', 'KOPERNİKYUM', 'MEİTNERİYUM'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'MOLAR HACİM',
                    options: ['MOLAR SABİT', 'MOLAR KÜTLE', 'MOLAR KESİR', 'MOLAR HACİM'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'OKSİDASYON',
                    options: ['MİKRODALGA', 'OKSİDASYON', 'NÖTÜRLEŞME', 'İNDİRGENME'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'TİTANYUM',
                    options: ['KALSİYUM', 'POTASYUM', 'TİTANYUM', 'SELENYUM'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'OKSİT',
                    options: ['OKSİT', 'OKTET', 'OZMOZ', 'METAL'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'iZOTOP',
                    options: ['İZOTON', 'İZOTOP', 'İYONİK', 'KATYON'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'TORYUM',
                    options: ['TALYUM', 'RADYUM', 'TORYUM', 'TULYUM'],
                },
                {
                    question: 'Bir kimya terimi?',
                    answer: 'FÜZYON',
                    options: ['FÜZYON', 'FÜSYON', 'FORMÜL', 'DİALİZ'],
                },
                {
                    question: 'Avrupa\'da bir şehir?',
                    answer: 'BİLBAO',
                    options: ['BERLİN', 'BİLBAO', 'BREMEN ', 'BRİSTOL'],
                },
                {
                    question: 'Avrupa\'da bir şehir?',
                    answer: 'VARŞOVA',
                    options: ['VARŞOVA', 'HAMBURG', 'SEVİLLA ', 'SARATOV'],
                },
                {
                    question: 'Avrupa\'da bir şehir?',
                    answer: 'MİLANO',
                    options: ['VİYANO', 'MİLANO', 'LONDRA', 'ZAGREB'],
                },
                {
                    question: 'Avrupa\'da bir şehir?',
                    answer: 'MADRİD',
                    options: ['MADRİD', 'SAMARA', 'ODESSA', 'POZNAN'],
                },
                {
                    question: 'Avrupa\'da bir şehir?',
                    answer: 'İSTANBUL',
                    options: ['İSTANBUL', 'HELSİNKİ', 'KOPENHAG', 'MARSİLYA'],
                },
                {
                    question: 'Avrupa\'da bir şehir?',
                    answer: 'BUDAPEŞTE',
                    options: ['BARSELONA', 'AMSTERDAM', 'BUDAPEŞTE', 'STOCKHOLM'],
                },
                {
                    question: 'Avrupa\'da bir şehir?',
                    answer: 'MİNSK',
                    options: ['MÜNİH', 'MİNSK', 'ATİNA', 'PARİS'],
                },
                {
                    question: 'Avrupa\'da bir şehir?',
                    answer: 'KİEL',
                    options: ['PERM', 'RİGA', 'LYON', 'KİEL'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'BİT',
                    options: ['FİL', 'FOK', 'BİT', 'KOÇ'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'AYI',
                    options: ['ARI', 'AYI', 'KUŞ', 'KAZ'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'EŞEK',
                    options: ['EŞEK', 'DEVE', 'FARE', 'KEDİ'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'PARS',
                    options: ['PARS', 'PİRE', 'MORS', 'PUMA'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'KUZU',
                    options: ['KUĞU', 'KURT', 'KUZU', 'KENE'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'AKREP',
                    options: ['AKREP', 'ASLAN', 'BALIK ', 'BÖCEK'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'KOYUN',
                    options: ['KUMRU', 'KOYUN', 'KÖPEK', 'KARGA'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'YUNUS',
                    options: ['TAVUK', 'TİLKİ ', 'YILAN', 'YUNUS'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'GORİL',
                    options: ['DOMUZ', 'GEYİK', 'GORİL', 'HOROZ'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'BÜLBÜL',
                    options: ['BUFALO', 'BALİNA', 'BÜLBÜL', 'BAYKUŞ'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'KARACA',
                    options: ['KARACA', 'KUNDUZ', 'KAPLAN', 'KAYMAN'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'İGUANA',
                    options: ['İGUANA', 'İMPALA', 'İBİBİK ', 'İSPARİ'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'AKARLAR',
                    options: ['ANTİLOP', 'AHTOPOT', 'AKARLAR', 'AFALİNA'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'KARİDES',
                    options: ['KANGURU', 'KOKARCA', 'KARİDES', 'KANARYA'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'AMERİKAN KUNDUZU',
                    options: ['AMERİKAN TİMSAHI', 'AMERİKAN PORSUĞU', 'AMERİKAN KUNDUZU', 'AMERİKAN MANDASI'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'PAÇALI BAYKUŞ',
                    options: ['PAÇALI BAYKUŞ', 'PEÇELİ BAYKUŞ', 'YARASA DOĞANI', 'YARASA ŞAHİNİ'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'ENGEREK YILANI',
                    options: ['ENGEREK YILANI', 'FİRAVUN FARESİ', 'FİRAVUN KEDİSİ', 'AMERİKA TAPİRİ'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'DEĞNEK BÖCEĞİ',
                    options: ['DALGIÇ BÖCEĞİ', 'DEĞNEK BÖCEĞİ', 'ÖPÜCÜK BÖCEĞİ', 'TESBİH BÖCEĞİ'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'PASİFİK BAZASI',
                    options: ['PASİFİK BAZASI', 'PALMİYE LORİSİ', 'SAKALLI YARASA', 'SAÇAKLI YARASA'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'FİJİ ÇAKIR KUŞU',
                    options: ['FİJİ ÇAKIR KUŞU', 'ÖKSE ARDIÇ KUŞU', 'SARI KİRAZ KUŞU', 'SARI ŞEKER KUŞU'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'YEŞİL DÜDÜKÇÜN',
                    options: ['KÜÇÜK KERKENEZ', 'MEYVE YARASASI', 'MISIR AKBABASI', 'YEŞİL DÜDÜKÇÜN'],
                },
                {
                    question: 'Bir hayvan?',
                    answer: 'KAFKAS KÖSTEBEĞİ',
                    options: ['DARVİN KURBAĞASI', 'KAFKAS KÖSTEBEĞİ', 'TRAKYA GELENGİSİ', 'PAÇALI BAĞIRTLAK'],
                },
            ],
            timer: null,
            rndQuestion: null,
            answerArr: [],
            answer: null,
            answerLength: null,
            startingPoint: null,
            row1: null,
            letterIndex: 0,
            answerSize: null,
            startingPointsMini: ["17", "17"],
            startingPointsBig: ["13", "17", "17", "13"],
            timerElem: null,
            currentScene: 0,
            scenes: [
                {
                    id: 0,
                    html: `
       <div class="kelime-tahmin-wrapper" id="container">
            <span id="timer">4:00</span>
            <h2 class="count-text" id="letterCount">Kalan harf hakkınız:7</h2>
            <span id="goChoices"><span class="button-green"></span><span class="button-text">Şıklardan Seç</span></span>
            <div id='backButton' href="/games" data-link="" tabindex="-1">
                <div class='button-blue'></div>
                <span class='button-text'>Geri</span>
            </div>   
            <div id="question">
            </div>
            <div class="word-area">
                <table class="letter-area">
                <tr id="letters">
                  <td id="letter0" class="active">A</td>
                  <td id="letter1">B</td>
                  <td id="letter2">C</td>
                  <td id="letter3">Ç</td>
                  <td id="letter4">D</td>
                  <td id="letter5">E</td>
                  <td id="letter6">F</td>
                  <td id="letter7">G</td>
                  <td id="letter8">Ğ</td>
                  <td id="letter9">H</td>
                  <td id="letter10">I</td>
                  <td id="letter11">İ</td>
                  <td id="letter12">J</td>
                  <td id="letter13">K</td>
                  <td id="letter14">L</td>
                  <td id="letter15">M</td>
                  <td id="letter16">N</td>
                  <td id="letter17">O</td>
                  <td id="letter18">Ö</td>
                  <td id="letter19">P</td>
                  <td id="letter20">R</td>
                  <td id="letter21">S</td>
                  <td id="letter22">Ş</td>
                  <td id="letter23">T</td>
                  <td id="letter24">U</td>
                  <td id="letter25">Ü</td>
                  <td id="letter26">V</td>
                  <td id="letter27">Y</td>
                  <td id="letter28">Z</td>
                </tr>
              </table>
                <table class="block-table">
                  <tr id="row0">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
                <table class="block-table">
                  <tr id="row1">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
                <table class="block-table">
                  <tr id="row2">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
                <table class="block-table">
                  <tr id="row3">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              
            </div>
       `
                    ,
                    keyHandler: (e) => this.scene0KeyHandler(e),
                    bg: bg0,
                    before: () => {
                        this.refactor();
                    },
                    after: () => {
                        this.init0();
                        this.addOpen();
                        this.countDownTimer();
                    }
                },
                {
                    id: 1,
                    html: `
        <div class="answer-choices">
            <div id='backButton' class='solo' href="/games" data-link="" tabindex="-1">
                <div class='button-blue'></div>
                <span class='button-text'>Geri</span>
            </div>   
          <div class="option-wrapper">
            <div>
              <span class="option selected-option" id="option11"></span>
              <span class="option" id="option12"></span>
            </div>
            <div>
              <span class="option" id="option21"></span>
              <span class="option" id="option22"></span>
            </div>
          </div>
        </div>
                    `,
                    keyHandler: (e) => this.scene1KeyHandler(e),
                    bg: bg1,
                    before: () => {
                        clearInterval(this.globalVariables.timer); 
                        this.fillAnswers();
                    },
                    after: ''
                },
                {
                    id: 2,
                    html: `
        <div id='backButton' class='solo' href="/games" data-link="" tabindex="-1">
                <div class='button-blue'></div>
                <span class='button-text'>Geri</span>
        </div>   
        <div class="answer-wrapper">
           <span>Cevap:</span>
           <span id="answer"></span>
        </div>
                    `,
                    keyHandler: (e) => this.scene2KeyHandler(e),
                    bg: bg2,
                    before: () => {
                        clearInterval(this.globalVariables.timer);
                        document.getElementById("answer").innerHTML = this.globalVariables.answer;
                    },
                    after: ''
                },
                {
                    id: 3,
                    html: `
        <div id='backButton' class='solo' href="/games" data-link="" tabindex="-1">
                <div class='button-blue'></div>
                <span class='button-text'>Geri</span>
        </div>   
        <div class="answer-wrapper">
           <span>Cevap:</span>
           <span id="answer"></span>
        </div>
                    `,
                    keyHandler: (e) => this.scene3KeyHandler(e),
                    bg: bg3,
                    before: () => {
                        clearInterval(this.globalVariables.timer);
                        document.getElementById("answer").innerHTML = this.globalVariables.answer;
                    },
                    after: ''
                }
            ]
        };
    }

    async getHtml() {
        return this.globalVariables.scenes[this.globalVariables.currentScene].html;
    }

    async afterRender() {
        const currentScene = this.globalVariables.scenes[this.globalVariables.currentScene];
        if (currentScene.before) currentScene.before();
        if (currentScene.after) currentScene.after();
        this.globalVariables.navigationHandler = (e) => this.handleKeyInput(e);
        document.addEventListener('keydown', this.globalVariables.navigationHandler);
    }

    handleKeyInput(e) {
        const currentScene = this.globalVariables.scenes[this.globalVariables.currentScene];
        currentScene.keyHandler(e);
    }

    navigateScreen(sceneId) {
        document.removeEventListener('keydown', this.globalVariables.navigationHandler);
        this.globalVariables.currentScene = sceneId;
        const newScene = this.globalVariables.scenes[sceneId];
        document.getElementById('container').innerHTML = newScene.html;
        document.querySelector('.kelime-tahmin-wrapper').style.backgroundImage = `url(${newScene.bg})`;
        this.globalVariables.navigationHandler = (e) => this.handleKeyInput(e);
        document.addEventListener('keydown', this.globalVariables.navigationHandler);
        const currentScene = this.globalVariables.scenes[this.globalVariables.currentScene];
        if (currentScene.before) currentScene.before();
        if (currentScene.after) currentScene.after();
    }

    init0() {
        this.globalVariables.rndQuestion = this.globalVariables.data[Math.floor(Math.random() * this.globalVariables.data.length)];
        this.globalVariables.answer = this.globalVariables.rndQuestion.answer;
        this.globalVariables.answerArr = this.globalVariables.rndQuestion.answer.split(" ");
        this.globalVariables.answerLength = this.globalVariables.rndQuestion.answer.length;
        this.globalVariables.startingPoint = Math.floor((17 - this.globalVariables.answerLength) / 2);
        this.globalVariables.row1 = document.getElementById("row1");
        this.globalVariables.letterIndex = 0;
        this.globalVariables.answerSize = this.globalVariables.rndQuestion.answer.split(" ").length;
        this.globalVariables.rowMini = ["row1", "row2", "row0", "row3",];
        this.globalVariables.rowBig = ["row0", "row1", "row2", "row3"];
        this.globalVariables.startingPointsMini = ["17", "17"];
        this.globalVariables.startingPointsBig = ["13", "17", "17", "13"];
        this.globalVariables.timerElem = document.getElementById("timer");
        document.getElementById("broadcast-video").style.zIndex = -1;
    }

    addOpen() {
        document.getElementById("question").innerHTML = this.globalVariables.rndQuestion.question;
        for (var i = 0; i < this.globalVariables.answerSize; i++) {
            var charIndex = 0;
            var rowArr = this.globalVariables.answerSize <= 2 ? this.globalVariables.rowMini : this.globalVariables.rowBig;
            var rowId = rowArr[i];
            var row = document.getElementById(rowId);
            var startingPoints = this.globalVariables.answerSize <= 2 ? this.globalVariables.startingPointsMini : this.globalVariables.startingPointsBig;
            var startingPoint = Math.floor((startingPoints[i] - this.globalVariables.answerArr[i].length) / 2);
            for (var index = startingPoint; index < startingPoint + this.globalVariables.answerArr[i].length; index++) {
                row.children[index].classList.add("open");
                row.children[index].classList.add("font-size-0");
                row.children[index].innerHTML = this.globalVariables.answerArr[i].charAt(charIndex);
                charIndex++;
            }
        }
    }

    scene0KeyHandler(e) {
        switch (e.keyCode) {
            case TVKEYS.VK_LEFT:
                this.moveLeft();
                break;
            case TVKEYS.VK_RIGHT:
                this.moveRight();
                break;
            case TVKEYS.VK_ENTER:
                this.checkLetter();
                break;
            case TVKEYS.VK_GREEN:
                this.checkNavigate1();
                break;
            case TVKEYS.VK_RED:
                deactivateApp();
                break;
            case TVKEYS.VK_BLUE:
                document.removeEventListener('keydown', this.globalVariables.navigationHandler);
                document.getElementById("broadcast-video").style.zIndex = 1;
                document.getElementById('backButton').click();
                break;
            default:
                break;
        }
    }

    scene1KeyHandler(e) {
        switch (e.keyCode) {
            case TVKEYS.VK_LEFT:
                this.answerLeft();
                break;
            case TVKEYS.VK_RIGHT:
                this.answerRight();
                break;
            case TVKEYS.VK_UP:
                this.answerUp();
                break;
            case TVKEYS.VK_DOWN:
                this.answerDown();
                break;
            case TVKEYS.VK_ENTER:
                this.checkAnswer();
                break;
            case TVKEYS.VK_RED:
                deactivateApp();
                break;
            case TVKEYS.VK_BLUE:
                document.removeEventListener('keydown', this.globalVariables.navigationHandler);
                document.getElementById("broadcast-video").style.zIndex = 1;
                document.getElementById('backButton').click();
                break;
            default:
                break;
        }
    }

    scene2KeyHandler(e) {
        switch (e.keyCode) {
            case TVKEYS.VK_YELLOW:
                this.navigateScreen(0);
                break;
            case TVKEYS.VK_RED:
                deactivateApp();
                break;
            case TVKEYS.VK_BLUE:
                document.removeEventListener('keydown', this.globalVariables.navigationHandler);
                document.getElementById("broadcast-video").style.zIndex = 1;
                document.getElementById('backButton').click();
                break;
            default:
                break;
        }
    }

    scene3KeyHandler(e) {
        switch (e.keyCode) {
            case TVKEYS.VK_YELLOW:
                this.navigateScreen(0);
                break;
            case TVKEYS.VK_RED:
                deactivateApp();
                break;
            case TVKEYS.VK_BLUE:
                document.removeEventListener('keydown', this.globalVariables.navigationHandler);
                document.getElementById("broadcast-video").style.zIndex = 1;
                document.getElementById('backButton').click();
                break;
            default:
                break;
        }
    }

    moveRight() {
        var previousLetter = document.getElementById("letter" + this.globalVariables.letterIndex);
        previousLetter.classList.remove("active");
        if (this.globalVariables.letterIndex === 28) {
            this.globalVariables.letterIndex = -1;
        }
        this.globalVariables.letterIndex += 1;
        document.getElementById("letter" + this.globalVariables.letterIndex).classList.add("active");
    }

    moveLeft() {
        var previousLetter = document.getElementById("letter" + this.globalVariables.letterIndex);
        previousLetter.classList.remove("active");
        if (this.globalVariables.letterIndex === 0) {
            this.globalVariables.letterIndex = 29;
        }
        this.globalVariables.letterIndex -= 1;
        document.getElementById("letter" + this.globalVariables.letterIndex).classList.add("active");
    }

    checkLetter() {
        var currentLetter = document.getElementById("letter" + this.globalVariables.letterIndex);
        if (this.globalVariables.checkedLetters.indexOf(currentLetter.innerHTML) >= 0) {
            console.log("letter included");
        } else {
            this.globalVariables.checkedLetters.push(currentLetter.innerHTML);
            for (var i = 0; i < this.globalVariables.answerSize; i++) {
                var rowArr = this.globalVariables.answerSize <= 2 ? this.globalVariables.rowMini : this.globalVariables.rowBig;
                var rowId = rowArr[i];
                var row = document.getElementById(rowId);
                var startingPoints = this.globalVariables.answerSize <= 2 ? this.globalVariables.startingPointsMini : this.globalVariables.startingPointsBig;
                var startingPoint = Math.floor((startingPoints[i] - this.globalVariables.answerArr[i].length) / 2);
                for (var index = startingPoint; index < startingPoint + this.globalVariables.answerArr[i].length; index++) {
                    if (currentLetter.innerHTML == row.children[index].innerHTML) {
                        row.children[index].classList.remove("font-size-0");
                        row.children[index].classList.add("font-size-48");
                    }
                    currentLetter.classList.add("bg-red");
                    if (document.getElementsByClassName("font-size-0")[0] == undefined) {
                        setTimeout(function () {
                            this.navigateScreen(2);
                        }.bind(this), 500);
                    }
                }
            }
            this.globalVariables.answer.includes(currentLetter.innerHTML) ? currentLetter.classList.add("bg-green") : this.checkRightOfUse();
        }
    }


    countDownTimer() {
        this.globalVariables.timer = setInterval(() => {
            var minutes = parseInt(this.globalVariables.maxTime / 60, 10);
            var seconds = parseInt(this.globalVariables.maxTime % 60, 10);
            if (seconds < 10) { seconds = "0" + seconds; }
            this.globalVariables.timerElem.innerHTML = minutes + ":" + seconds;
            if (this.globalVariables.maxTime <= 0) {
                clearInterval(this.globalVariables.timer);
                this.navigateScreen(3);
            }
            this.globalVariables.maxTime--;
        }, 1000);
    }

    checkRightOfUse() {
        var countElem = document.getElementById("letterCount");
        if (this.globalVariables.rightOfUse > 1) {
            this.globalVariables.rightOfUse -= 1;
            countElem.innerHTML = 'Kalan harf hakkınız: ' + this.globalVariables.rightOfUse;
            if (this.globalVariables.rightOfUse <= 3) {
                var greenElem = document.getElementById("goChoices");
                greenElem.style.display = "inline-block";
            }
        } else {
            this.navigateScreen(3);
        }
    }

    answerRight() {
        if (this.globalVariables.answerIndex === 11) {
            document.getElementById("option" + this.globalVariables.answerIndex).classList.remove("selected-option");
            this.globalVariables.answerIndex = 12;
            document.getElementById("option" + this.globalVariables.answerIndex).classList.add("selected-option");
        }
        if (this.globalVariables.answerIndex === 21) {
            document.getElementById("option" + this.globalVariables.answerIndex).classList.remove("selected-option");
            this.globalVariables.answerIndex = 22;
            document.getElementById("option" + this.globalVariables.answerIndex).classList.add("selected-option");
        }
    }

    answerLeft() {
        if (this.globalVariables.answerIndex === 12) {
            document.getElementById("option" + this.globalVariables.answerIndex).classList.remove("selected-option");
            this.globalVariables.answerIndex = 11;
            document.getElementById("option" + this.globalVariables.answerIndex).classList.add("selected-option");
        }
        if (this.globalVariables.answerIndex === 22) {
            document.getElementById("option" + this.globalVariables.answerIndex).classList.remove("selected-option");
            this.globalVariables.answerIndex = 21;
            document.getElementById("option" + this.globalVariables.answerIndex).classList.add("selected-option");
        }
    }


    answerUp() {
        if (this.globalVariables.answerIndex === 21) {
            document.getElementById("option" + this.globalVariables.answerIndex).classList.remove("selected-option");
            this.globalVariables.answerIndex = 11;
            document.getElementById("option" + this.globalVariables.answerIndex).classList.add("selected-option");
        }
        if (this.globalVariables.answerIndex === 22) {
            document.getElementById("option" + this.globalVariables.answerIndex).classList.remove("selected-option");
            this.globalVariables.answerIndex = 12;
            document.getElementById("option" + this.globalVariables.answerIndex).classList.add("selected-option");
        }
    }

    answerDown() {
        if (this.globalVariables.answerIndex === 11) {
            document.getElementById("option" + this.globalVariables.answerIndex).classList.remove("selected-option");
            this.globalVariables.answerIndex = 21;
            document.getElementById("option" + this.globalVariables.answerIndex).classList.add("selected-option");
        }
        if (this.globalVariables.answerIndex === 12) {
            document.getElementById("option" + this.globalVariables.answerIndex).classList.remove("selected-option");
            this.globalVariables.answerIndex = 22;
            document.getElementById("option" + this.globalVariables.answerIndex).classList.add("selected-option");
        }
    }

    fillAnswers() {
        document.getElementById("option11").innerHTML = this.globalVariables.rndQuestion.options[0];
        document.getElementById("option12").innerHTML = this.globalVariables.rndQuestion.options[1];
        document.getElementById("option21").innerHTML = this.globalVariables.rndQuestion.options[2];
        document.getElementById("option22").innerHTML = this.globalVariables.rndQuestion.options[3];
    }

    checkAnswer() {
        if (document.getElementById("option" + this.globalVariables.answerIndex).innerHTML == this.globalVariables.answer) {
            //this.showAnswer();
            //setTimeout(function () {
            this.navigateScreen(2);
            //}.bind(this), 2000);
        } else {
            this.navigateScreen(3);
        }
    }

    showAnswer() {
        //document.getElementById("layer1").style.opacity = 0;
        document.getElementById("question").innerHTML = this.globalVariables.rndQuestion.question;
        //var currentLetter = document.getElementById("letter" + this.globalVariables.letterIndex);
        //var charIndex = 0;
        for (var i = 0; i < this.globalVariables.answerSize; i++) {
            var rowArr = this.globalVariables.answerSize <= 2 ? this.globalVariables.rowMini : this.globalVariables.rowBig;
            var rowId = rowArr[i];
            var row = document.getElementById(rowId);
            var startingPoints = this.globalVariables.answerSize <= 2 ? this.globalVariables.startingPointsMini : this.globalVariables.startingPointsBig;
            var startingPoint = Math.floor((startingPoints[i] - this.globalVariables.answerArr[i].length) / 2);
            for (var index = startingPoint; index < startingPoint + this.globalVariables.answerArr[i].length; index++) {
                row.children[index].classList.remove("font-size-0");
            }
        }
    }

    refactor() {
        /*
        //document.getElementById("layer1").style.opacity = 1;
        clearInterval(this.globalVariables.timer);
        this.globalVariables.maxTime = 240;
        document.getElementById("question").innerHTML = "";
        this.globalVariables.rightOfUse = 7;
        this.globalVariables.checkedLetters.length = 0;
        document.getElementById("letterCount").innerHTML = this.globalVariables.rightOfUse;
        document.getElementById("goChoices").style.display = "none";
        //document.getElementById("layer0").style.zIndex = "1000";
        //document.getElementById("layer1").style.zIndex = "999";
        //document.getElementById("layer2").style.zIndex = "998";
        //document.getElementById("layer3").style.zIndex = "997";

        var activeList = document.getElementsByClassName("active");
        for (var i = 0; i < activeList.length; i++) {
            activeList[i].classList.remove("active");
            i -= 1;
        }

        document.getElementById("letter0").classList.add("active");

        var openList = document.getElementsByClassName("open");
        for (var j = 0; j < openList.length; j++) {
            openList[j].innerHTML = "";
            openList[j].classList.remove("open", "font-size-48", "font-size-0");
            j -= 1;
        }

        var letters = document.getElementById("letters").children;
        for (var l = 0; l < letters.length; l++) {
            letters[l].classList.remove("bg-red", "bg-green");
        }

        document.getElementsByClassName("selected-option")[0].classList.remove("selected-option");
        document.getElementById("option11").classList.add("selected-option");
        this.globalVariables.answerIndex = 11;
        //this.navigateScreen(0);
        */
        clearInterval(this.globalVariables.timer);
        this.globalVariables.rowMini = ['row1', 'row2', 'row0', 'row3'];
        this.globalVariables.rowBig = ['row0', 'row1', 'row2', 'row3'];
        this.globalVariables.startingPointMini = [17, 17];
        this.globalVariables.startingPointBig = [13, 17, 17, 13];
        this.globalVariables.lettersLength = 29;
        this.globalVariables.answerIndex = 11;
        this.globalVariables.maxTime = 240;
        this.globalVariables.rightOfUse = 7;
        this.globalVariables.checkedLetters = [];
        this.globalVariables.timer = null;
        this.globalVariables.rndQuestion = null;
        this.globalVariables.answerArr = [];
        this.globalVariables.answer = null;
        this.globalVariables.answerLength = null;
        this.globalVariables.startingPoint = null;
        this.globalVariables.row1 = null;
        this.globalVariables.letterIndex = 0;
        this.globalVariables.answerSize = null;
        this.globalVariables.startingPointsMini = ["17", "17"];
        this.globalVariables.startingPointsBig = ["13", "17", "17", "13"];
        this.globalVariables.timerElem = null;

        //this.init0();
        //this.addOpen();
        //this.countDownTimer();
    }


    checkNavigate1() {
        if (this.globalVariables.rightOfUse <= 3) {
            this.navigateScreen(1);
        }
    }
}

export default KelimeTahmin;

